import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import _ from 'lodash'
import { List, fromJS, Map } from 'immutable'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose } from 'redux'

import StackedBarTimeSeries from 'components/StackedBarTimeSeries'
import Heatmap from 'components/HeatMap'
import { getAlertLevelColorHML } from 'utils/widgetUtils'
import { SEVERITY_CATEGORIES } from 'appConstants'
import sizeMe from 'components/SizeMe'
import { selectControlFrameworksForUser } from 'containers/ControlFrameworkData/selectors'
import {
  selectSwimlanes,
  selectSwimlanesBySrn,
} from 'containers/SonraiData/selectors'

import {
  selectCategoryRollupData,
  selectTimelineRollupData,
  selectLoadingRollupData,
  selectCategorySeriesTotals,
} from './selectors'
import { BlockShimmer } from 'components/ShimmerLoader'
import { GLOBAL_SWIMLANE_NAME } from 'appConstants'

const SizedHeatMap = sizeMe(Heatmap)
export class OverviewVis extends React.Component {
  styles = {
    wrapper: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridTemplateRows: '2fr 1fr',
      gridTemplateAreas: '"heatmap" "spacer" "barchart"',
      width: '100%',
      padding: '0.5em',
      gridRowGap: '1em',
      overflowX: 'auto',
    },
    wrapperNoGlobal: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridTemplateRows: '1fr',
      gridTemplateAreas: '"heatmap"',
      width: '100%',
      padding: '0.5em',
      gridRowGap: '1em',
      overflowX: 'auto',
    },
    heatmap: {
      gridArea: 'heatmap',
    },
    barchart: {
      gridArea: 'barchart',
    },
  }

  constructor(props) {
    super(props)
    this.state = {
      categoryNameMap: this.getCategoryNameMap(),
    }
  }

  onClickTimelineBar = datapoint => {
    const severity = datapoint.series ? datapoint.series.id : null
    this.props.setSeverity(severity)
  }

  getCategoryNameMap = () => {
    const cfMap = {}
    this.props.cf.forEach(cf => {
      cfMap[cf.get('srn')] = cf.get('shortDescription') || cf.get('title')
    })
    return fromJS(cfMap)
  }

  getCategories = () => {
    let categories = ['Access', 'Activity', 'Property']
    const accessibleSwombos = Object.keys(
      this.props.swimlanesKeyedProperly.toJS()
    )

    const cfSrns = this.props.cf
      .toList()
      .filter(cf => {
        if (cf.get('enabled') === true) {
          if (!cf.get('swimlaneSRNs') || cf.get('swimlaneSRNs').size < 1) {
            return true
          } else
            return (
              _.intersection(cf.get('swimlaneSRNs').toJS(), accessibleSwombos)
                .length > 0
            )
        }
        return false
      })
      .map(cf => cf.get('srn'))
      .toJS()

    return categories.concat(cfSrns)
  }

  getBarDataForSeverity = values => {
    return values
      .reduce((swimlaneCountMap, rollup) => {
        const swimlaneSrn = rollup.get('swimlaneSrns', List()).get(0)

        if (swimlaneCountMap.has(swimlaneSrn)) {
          return swimlaneCountMap.update(
            swimlaneSrn,
            total => (total += rollup.get('count'))
          )
        } else {
          return swimlaneCountMap.set(swimlaneSrn, rollup.get('count'))
        }
      }, Map())
      .toJS()
  }

  getBarData = () => {
    return [
      {
        name: 'Low Severity',
        id: SEVERITY_CATEGORIES.LOW,
        data: this.getBarDataForSeverity(
          this.props.timelineRollupData.get(SEVERITY_CATEGORIES.LOW, List())
        ),
      },
      {
        name: 'Medium Severity',
        id: SEVERITY_CATEGORIES.MEDIUM,
        data: this.getBarDataForSeverity(
          this.props.timelineRollupData.get(SEVERITY_CATEGORIES.MEDIUM, List())
        ),
      },
      {
        name: 'High Severity',
        id: SEVERITY_CATEGORIES.HIGH,
        data: this.getBarDataForSeverity(
          this.props.timelineRollupData.get(SEVERITY_CATEGORIES.HIGH, List())
        ),
      },
    ]
  }

  getSwimlaneHeatmapData = () => {
    if (!this.props.swimlanes) {
      return []
    }

    const categories = this.getCategories()

    const swimlaneSeries = this.props.swimlanes
      .toList()
      .sortBy(swimlane => (swimlane.get('title') || '').toLowerCase())
      .reverse()
      .toJS()
      .map(swimlane => {
        const dataByCategory = this.props.categoryRollupData.get(
          swimlane.title,
          Map()
        )

        const data = categories.map((categoryName, index) => {
          const rollupVal = dataByCategory.get(categoryName)

          let yValue = rollupVal
            ? _.round(rollupVal.get('riskScoreNumeric'))
            : 0
          const swimmiesForCF = this.props.cf.getIn(
            [categoryName, 'swimlaneSRNs'],
            List()
          )
          if (
            swimmiesForCF != null &&
            swimmiesForCF.size > 0 &&
            !swimmiesForCF.contains(swimlane.srn)
          ) {
            yValue = -1
          }

          const riskGrade = rollupVal ? rollupVal.get('riskScore') : 'A'

          return {
            x: `${index}`,
            y: yValue,
            displayValue: riskGrade,
          }
        })

        return {
          name: swimlane.title,
          data: data,
        }
      })

    return swimlaneSeries.reduce((sorted, series) => {
      if (series.name === GLOBAL_SWIMLANE_NAME) {
        return [series, ...sorted]
      }
      return [...sorted, series]
    }, [])
  }

  onClickHeatmap = datapoint => {
    let filters = {}
    if (datapoint.y) {
      filters.swimlane = datapoint.y
    }

    if (datapoint.x) {
      switch (datapoint.x) {
        case 'Access':
          filters.ticketType = 'Access'
          break
        case 'Activity':
          filters.ticketType = 'Activity'
          break
        case 'Property':
          filters.ticketType = 'Property'
          break
        default:
          filters.ticketType = 'Framework'
          filters.ticketKey = datapoint.x
      }
    }

    this.props.setFilters(filters)
  }

  getWrapperStyle = () => {
    const swimlaneCount = this.props.swimlanes.size

    const maxRowHeight = 50
    const minRowHeight = 24
    const topBarChartHeight = 85
    const xAxisHeight = 100

    const heightForFewSwimlanes =
      xAxisHeight + topBarChartHeight + maxRowHeight * swimlaneCount
    const heightForManySwimlanes =
      xAxisHeight + topBarChartHeight + minRowHeight * swimlaneCount

    if (!this.showTimeline()) {
      return {
        ...this.styles.wrapperNoGlobal,
        gridTemplateRows: `${
          swimlaneCount <= 15 ? heightForFewSwimlanes : heightForManySwimlanes
        }px 1fr`,
      }
    }

    return {
      ...this.styles.wrapper,
      gridTemplateRows: `${
        swimlaneCount <= 15 ? heightForFewSwimlanes : heightForManySwimlanes
      }px 1fr 250px`,
      overflow: 'auto',
    }
  }

  getHeatmapStyle = () => {
    if (this.props.loadingRollupData.get('category')) {
      return this.styles.heatmap
    }

    const numCategories = this.getCategories().length

    if (numCategories <= 25) {
      return this.styles.heatmap
    }

    const columnWidth = 50
    const barChartWidth = 70
    const yAxisWidth = 60

    return {
      ...this.styles.heatmap,
      width: `${columnWidth * numCategories + barChartWidth + yAxisWidth}px`,
    }
  }

  getGlobalValuesByCategory = (categories, globalTotals) => {
    let categoryTotalMap = {}

    categories.forEach((value, index) => {
      categoryTotalMap[value] = _.get(globalTotals, [index, 'displayValue'])
    })

    return categoryTotalMap
  }

  renderHeatmap = () => {
    if (this.props.loadingRollupData.get('category')) {
      return <BlockShimmer />
    }

    const categories = this.getCategories()
    const data = this.getSwimlaneHeatmapData()
    const globalData =
      (data.find(series => series.name === GLOBAL_SWIMLANE_NAME) || {}).data ||
      []

    const userHasGlobal = globalData.length > 1

    return (
      <SizedHeatMap
        showTotals
        data={data}
        xValues={categories}
        colorType="range"
        colors={[
          getAlertLevelColorHML(SEVERITY_CATEGORIES.LOW),
          getAlertLevelColorHML(SEVERITY_CATEGORIES.HIGH),
        ]}
        onClick={this.onClickHeatmap}
        seriesTotalsOverrides={this.props.categorySeriesTotals.toJS()}
        categoryTotalsOverrides={
          userHasGlobal
            ? this.getGlobalValuesByCategory(categories, globalData)
            : undefined
        }
        totalSeriesName={GLOBAL_SWIMLANE_NAME}
        categoryNameMap={this.state.categoryNameMap}
      />
    )
  }

  renderTimeline = () => {
    if (!this.showTimeline()) {
      return null
    }

    if (this.props.loadingRollupData.get('timeline')) {
      return (
        <Fragment>
          <div style={{ height: '25px' }} />
          <div style={{ height: '175px' }}>
            <BlockShimmer />
          </div>
        </Fragment>
      )
    }

    const timelineData = this.getBarData()
    const preselectedSeries = timelineData.find(
      series => series.id === this.props.visFilters.get('severityCategory')
    )

    return (
      <div
        style={{
          display: 'grid',
          gridTemplateRows: 'auto 1fr',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <span style={{ fontWeight: '400' }}>Number of Tickets </span>{' '}
        </div>
        <StackedBarTimeSeries
          colors={[
            getAlertLevelColorHML(SEVERITY_CATEGORIES.LOW),
            getAlertLevelColorHML(SEVERITY_CATEGORIES.MEDIUM),
            getAlertLevelColorHML(SEVERITY_CATEGORIES.HIGH),
          ]}
          data={timelineData}
          onClickBar={this.onClickTimelineBar}
          height={200}
          swimlanes={this.props.swimlanes}
          preSelectedSeries={preselectedSeries ? preselectedSeries.name : null}
          legendPosition="left"
        />
      </div>
    )
  }

  showTimeline = () => {
    return !!this.props.swimlanes.find(
      swimlane => swimlane.get('title') === GLOBAL_SWIMLANE_NAME
    )
  }

  render() {
    const wrapper = this.getWrapperStyle()

    return (
      <div style={wrapper}>
        <div style={this.getHeatmapStyle()}>{this.renderHeatmap()}</div>
        <div className="spacer" />
        <div style={this.styles.barchart}>{this.renderTimeline()}</div>
      </div>
    )
  }
}

OverviewVis.propTypes = {
  categoryRollupData: ImmutablePropTypes.iterable.isRequired,
  categorySeriesTotals: ImmutablePropTypes.map.isRequired,
  cf: ImmutablePropTypes.map.isRequired,
  loadingRollupData: ImmutablePropTypes.map,
  setFilters: PropTypes.func.isRequired,
  setSeverity: PropTypes.func.isRequired,
  swimlanes: ImmutablePropTypes.map,
  swimlanesKeyedProperly: ImmutablePropTypes.map,
  timelineRollupData: ImmutablePropTypes.iterable.isRequired,
  visFilters: ImmutablePropTypes.map,
}

const mapStateToProps = createStructuredSelector({
  cf: selectControlFrameworksForUser,
  swimlanes: selectSwimlanes,
  swimlanesKeyedProperly: selectSwimlanesBySrn,
  categoryRollupData: selectCategoryRollupData,
  loadingRollupData: selectLoadingRollupData,
  timelineRollupData: selectTimelineRollupData,
  categorySeriesTotals: selectCategorySeriesTotals,
})

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(OverviewVis)
