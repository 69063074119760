/**
 *
 * SecurityCenterResourceCard
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { fromJS } from 'immutable'
import { Alert } from 'reactstrap'

import DropdownActions, {
  DropdownAnchor,
  DropdownMenu,
  DropdownItem,
} from 'components/DropdownActions'

import BorderedCard from 'components/BorderedCard'
import CloudAccount from 'components/CloudAccount'
import Icon from 'components/Icon'
import Tag from 'components/Tag'
import { getAlertLevelColor } from 'utils/widgetUtils'
import {
  getIconMapping,
  isCrmType,
  exists,
  getNameFromSrn,
} from 'utils/sonraiUtils'
import { SEVERITY_CATEGORIES } from 'appConstants'

class SecurityCenterResourceCard extends React.PureComponent {
  constructor(props) {
    super(props)
    this.selectedResourceRef = React.createRef()

    this.state = {
      toggle: false,
    }
  }

  componentDidMount() {
    const { srn } = this.props.resource
    const { selectedResourceSrn } = this.props
    if (srn === selectedResourceSrn) {
      this.selectedResourceRef.current.scrollIntoView()
    }
  }

  styles = {
    container: {
      margin: '10px',
      width: '360px',
      animation: 'fade-in-top 1s cubic-bezier(0.39, 0.575, 0.565, 1) both',
    },
    card: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridTemplateRows: '2em auto auto',
      gridTemplateAreas: '"banner" "props" "notices"',
      padding: 0,
      cursor: 'pointer',
    },
    banner: {
      display: 'grid',
      gridTemplateColumns: 'auto 1fr auto',
      gridArea: 'banner',
      gridColumnGap: '0.5em',
      gridTemplateAreas: '"icon name menu"',
      boxShadow: '0 2px 4px 0 rgba(0,0,0,0.50)',
      margin: '0.5em -0.5em 0em -0.5em',
      padding: '0 1rem',
      fontWeight: '400',
    },
    bannerIcon: {
      gridArea: 'icon',
      height: '2em',
    },
    bannerName: {
      gridArea: 'name',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    bannerManu: {
      gridArea: 'menu',
    },
    alertBanner: {
      color: '#000000',
      fontSize: '0.75em',
      maxHeight: '25px',
      padding: '0.25em',
      textAlign: 'center',
      borderBottomRightRadius: '4px',
      borderBottomLeftRadius: '4px',
      transition: 'box-shadow 0.2s ease-in-out',
      margin: 0,
      cursor: 'pointer',
    },
    props: {
      gridArea: 'props',
      fontSize: '12px',
      padding: '0.5em',
      overflow: 'hidden',
    },
    tags: {
      fontSize: '12px',
      overflow: 'hidden',
      paddingBottom: '0.5em',
      paddingTop: '0.25em',
    },
    tag: {
      margin: '0em',
      marginTop: '0.18em',
      marginLeft: '0.25em',
      marginBottom: '0.25em',
      fontSize: '12px',
    },
    propKey: {
      fontWeight: '300',
    },
    propValue: {
      fontWeight: '400',
    },
    dropdownItem: {
      padding: '0.5rem',
      width: '100%',
      fontSize: '0.9rem',
      border: 'none',
    },
    notices: {
      gridArea: 'notices',
    },
    inactiveText: {
      fontSize: '14px',
      fontWeight: '500',
      letterSpacing: '1px',
      color: '#DC3545',
      backgroundColor: '#FFF',
      padding: '3px',
      width: '100px',
      borderRadius: '0.8rem',
      textTransform: 'uppercase',
      textAlign: 'center',
    },
    inactive: {
      background: 'rgb(236, 239, 241)',
      width: '100%',
      borderBottomRightRadius: '4px',
      borderBottomLeftRadius: '4px',
      padding: '0.25rem',
      display: 'flex',
      justifyContent: 'center',
    },
  }

  getBannerColor = () => {
    const { highestAlertSeverity } = this.props.resource

    const roundedLevel = Math.floor(highestAlertSeverity / 10)

    return getAlertLevelColor(roundedLevel)
  }

  getBannerTextColor = () => {
    const {
      highestAlertSeverity,
      highestAlertSeverityCategory,
    } = this.props.resource
    return highestAlertSeverity >= 80 ||
      highestAlertSeverityCategory === SEVERITY_CATEGORIES.HIGH
      ? '#fff'
      : '#000'
  }

  getProps1 = () => {
    const { account } = this.props.resource
    return [
      <div key="prop1">
        <span style={this.styles.propKey}>Account: </span>
        <span style={this.styles.propValue}>
          <CloudAccount accountId={account} />
        </span>
      </div>,
    ]
  }

  getLastAlertInfo = () => {
    const {
      highestAlertSeverity,
      highestAlertSeverityCategory,
      lastAlertDate,
    } = this.props.resource

    const alertDate = exists(lastAlertDate)
      ? highestAlertSeverityCategory
        ? moment(lastAlertDate).format('ll')
        : moment(lastAlertDate).format('lll')
      : '-'

    if (!highestAlertSeverity && !lastAlertDate) {
      return (
        <span style={{ fontStyle: 'italic' }}>
          (No Alerts recorded for this resoruce)
        </span>
      )
    }
    if (this.props.sortProperty === 'timestamp') {
      return [
        <div key="prop2">
          <span style={this.styles.propKey}>Last Alert Time: </span>
          <span style={this.styles.propValue}>{alertDate}</span>
        </div>,
        <div key="prop1">
          <span style={this.styles.propKey}>Alert Severity: </span>
          <span style={this.styles.propValue}>
            {highestAlertSeverityCategory || highestAlertSeverity}
          </span>
        </div>,
      ]
    }

    return [
      <div key="prop1">
        <span style={this.styles.propKey}>Highest Alert Level: </span>
        <span style={this.styles.propValue}>
          {highestAlertSeverityCategory || highestAlertSeverity}
        </span>
      </div>,
      <div key="prop2">
        <span style={this.styles.propKey}>Highest Alert Time: </span>
        <span style={this.styles.propValue}>{alertDate}</span>
      </div>,
    ]
  }

  getTags = () => {
    return this.props.resource.tagSet
      ? this.props.resource.tagSet.map((keyvalue, index) => {
          const split = keyvalue.split(':')

          return (
            <Tag
              key={index}
              tag={fromJS({ key: split[0], value: split[1] })}
              style={this.styles.tag}
              tagHeight="20px"
            />
          )
        })
      : undefined
  }

  handleDisableMonitoring = () => {
    this.setState({ toggle: false })
    this.props.disableMonitoring(this.props.resource)
  }

  handleEnableMonitoring = () => {
    this.setState({ toggle: false })
    this.props.enableMonitoring(this.props.resource)
  }

  render() {
    const {
      friendlyName,
      name,
      srn,
      active,
      __typename,
      sonraiConfig,
    } = this.props.resource
    const { selectedResourceSrn } = this.props
    const tags = this.getTags()
    let isMonitored = false
    if (sonraiConfig && sonraiConfig.includes('MONITORED')) {
      isMonitored = true
    }
    const isCrm = isCrmType(srn)
    const showNoMonitorBanner = isCrm && !isMonitored
    const resourceName = friendlyName || name || getNameFromSrn(srn)

    return (
      <div
        style={this.styles.container}
        ref={this.selectedResourceRef}
        onClick={() => this.props.selectCard(this.props.resource)}
      >
        <BorderedCard
          key={`${srn}-card`}
          style={{
            ...this.styles.card,
            backgroundColor:
              srn !== selectedResourceSrn ? '#FFFFFF' : '#e8effc',
            boxShadow:
              srn !== selectedResourceSrn
                ? '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)'
                : '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
          }}
        >
          <div
            style={{
              ...this.styles.banner,
              background: this.getBannerColor(),
              color: this.getBannerTextColor(),
              boxShadow:
                srn !== selectedResourceSrn
                  ? '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)'
                  : '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
            }}
          >
            <div style={this.styles.bannerIcon} title={__typename}>
              <Icon
                fa
                name={
                  getIconMapping(__typename) &&
                  getIconMapping(__typename).faIcon
                }
                style={{
                  fontSize: '14px',
                }}
                color={this.getBannerTextColor()}
              />
            </div>
            <div title={resourceName} style={this.styles.bannerName}>
              {resourceName}
            </div>

            <div>
              <DropdownActions>
                <DropdownAnchor borderless style={{ padding: '0 0.5em' }}>
                  <Icon
                    fa
                    name="ellipsis-v"
                    color={this.getBannerTextColor()}
                  />
                </DropdownAnchor>
                <DropdownMenu>
                  <DropdownItem
                    style={this.styles.dropdownItem}
                    title="Disable Monitoring"
                    onClick={this.handleDisableMonitoring}
                    disabled={!isMonitored}
                  >
                    Disable Monitoring
                  </DropdownItem>
                  <DropdownItem
                    style={this.styles.dropdownItem}
                    title="Enable Monitoring"
                    onClick={this.handleEnableMonitoring}
                    disabled={isMonitored}
                  >
                    Enable Monitoring
                  </DropdownItem>
                </DropdownMenu>
              </DropdownActions>
            </div>
          </div>

          <div style={this.styles.props}>
            <div style={this.styles.propsCont}>
              <div>
                <span style={this.styles.propKey}>Account: </span>
                <span style={this.styles.propValue}>
                  <CloudAccount accountId={this.props.resource.account} />
                </span>
              </div>
              {this.getLastAlertInfo()}
            </div>

            <div style={this.styles.tags}>
              <span style={{ display: 'flex', flexWrap: 'wrap' }}>
                {tags && tags.slice(0, 5)}
                {tags && tags.length > 5 ? (
                  <span style={{ margin: '0.25em' }}>{`+ ${
                    tags.length - 5
                  } more`}</span>
                ) : undefined}
              </span>
            </div>
          </div>
          <div style={this.styles.notices}>
            {showNoMonitorBanner && (
              <Alert style={this.styles.alertBanner} color="secondary">
                Unmonitored Resource
              </Alert>
            )}
            {!active && (
              <div style={this.styles.inactive}>
                <div style={this.styles.inactiveText}>Inactive</div>
              </div>
            )}
          </div>
        </BorderedCard>
      </div>
    )
  }
}

SecurityCenterResourceCard.propTypes = {
  account: PropTypes.string,
  srn: PropTypes.string,
  highestAlertSeverity: PropTypes.number,
  resource: PropTypes.object,
  selectCard: PropTypes.func.isRequired,
  selectedResourceSrn: PropTypes.string,
  disableMonitoring: PropTypes.func,
  enableMonitoring: PropTypes.func,
  sortProperty: PropTypes.string,
}

export default SecurityCenterResourceCard
