import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import qs from 'query-string'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { push } from 'connected-react-router'
import DropdownActions, {
  DropdownAnchor,
  DropdownMenu,
  DropdownItem,
} from 'components/DropdownActions'
import Pill from 'components/Badge/Pill'
import TextLink from 'components/TextLink'
import CenterContent from 'components/CenterContent'
import Button from 'components/Button'
import SectionHeader from 'components/SectionHeader'
import Tooltip from 'components/Tooltip'
import BorderlessButton from 'components/BorderlessButton'
import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import { selectSwimlanes } from 'containers/SonraiData/selectors'
import { selectUserProfile } from 'containers/UserProfileData/selectors'
import { setSecurityCenterWelcomeModal } from 'containers/UserProfileData/actions'
import LoadingAnim from 'components/LoadingAnim'
import ScWelcomeScreen from 'components/ScWelcomeScreen'
import Icon from 'components/Icon'
import FilterVis from './FilterVis'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import {
  selectLoadedControlGroups,
  selectLoadedPolicies,
  selectControlGroups,
} from 'containers/ControlFrameworkData/selectors'
import {
  setSelectedResourceId,
  setSelectedSwimlaneName,
  setSelectedCategory,
  fetchRollupData,
  fetchCategoryRollupData,
  setVisFilters,
  clearSelectedResourceId,
  toggleSCInfoModal,
  setSelectedSeverity,
  setStatusFilter,
} from './actions'
import scSaga from './sagas'
import {
  selectSelectedResourceId,
  selectView,
  selectSelectedSwimlaneName,
  selectVisFilters,
  selectSelectedCategory,
  selectSCInfoModal,
  selectSelectedSeverity,
  selectSelectedResource,
  selectStatus,
  selectLoadingRollupData,
} from './selectors'
import reducer from './reducer'
import { VIEWS } from './constants'
import OverviewVis from './OverviewVis'
import permissionChecker from 'containers/PermissionChecker'

export class SecurityCenter extends React.Component {
  constructor(props) {
    super(props)
    this.styles = {
      content: {
        padding: '1em',
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        gridTemplateAreas: '"pagetitle" "content"',
        height: '100%',
      },
      dateFilter: {
        gridArea: 'datefilter',
      },
      pageTitle: {
        display: 'grid',
        gridTemplateColumns: '1fr 2fr 1fr',
        gridArea: 'pagetitle',
        gridTemplateAreas: '"title datefilter action"',
      },
      actionButtons: {
        textAlign: 'right',
      },
      preFilterPill: {
        fontSize: '1em',
      },
      tooltip: {
        display: 'flex',
        flexDirection: 'column',
        padding: '5px',
      },
    }
    this.hasPermission = props.userHasPermission({
      permissionName: 'view.data',
    })

    if (this.hasPermission) {
      this.checkUrl()

      if (
        props.loadedControlGroups &&
        props.loadedPolicies &&
        props.swimlanes
      ) {
        props.fetchRollupData({
          status: props.status,
          severityCategory: props.selectedSeverity,
        })
      }
    }

    this.state = {
      showWelcome: !window.localStorage.getItem('securitycenterwelcomemodal'),
      resizing: false,
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions)
    if (this.resizeTimer) {
      clearTimeout(this.resizeTimer)
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions)
  }

  updateDimensions = () => {
    this.setState({
      resizing: true,
    })

    clearTimeout(this.resizeTimer)
    this.resizeTimer = setTimeout(() => {
      this.setState({
        resizing: false,
      })
    }, 500)
  }

  componentDidUpdate(oldProps) {
    if (
      ((!oldProps.loadedControlGroups ||
        !oldProps.loadedPolicies ||
        !oldProps.swimlanes) &&
        this.props.loadedControlGroups &&
        this.props.loadedPolicies &&
        this.props.swimlanes) ||
      this.props.status !== oldProps.status
    ) {
      this.props.fetchRollupData({
        status: this.props.status,
        severityCategory: this.props.selectedSeverity,
      })
    }
  }

  checkUrl = () => {
    const {
      swimlane,
      resourceId,
      category,
      severityCategory,
      status,
      view, //eslint-disable-line
      selectedResourceId,
      ...rest
    } = qs.parse(this.props.location.search)

    if (swimlane) {
      this.props.setSelectedSwimlaneName(swimlane)
    }

    if (category) {
      this.props.setSelectedCategory(category)
    }

    if (resourceId) {
      this.props.setSelectedResourceId(resourceId)
    }

    if (selectedResourceId) {
      this.props.setSelectedResourceId(selectedResourceId)
    }

    if (severityCategory) {
      this.props.setSelectedSeverity(severityCategory)
    }

    if (status) {
      this.props.setStatusFilter(status)
    }

    if (severityCategory) {
      this.props.setVisFilters({ ...rest, severityCategory })
    } else {
      this.props.setVisFilters(rest)
    }
  }

  handleWelcomeModal = () => {
    this.props.toggleSCInfoModal()
    this.props.setSecurityCenterWelcomeModal()
  }

  toggleSCInfoModal = () => {
    this.props.toggleSCInfoModal()
  }

  setSelectedSwimlaneName = name => {
    this.props.setSelectedSwimlaneName(name)

    const currentParams = qs.parse(this.props.location.search)

    this.props.push({
      search: qs.stringify({
        ...currentParams,
        swimlane: name,
        view: VIEWS.RESOURCES,
      }),
    })
  }

  setSeverity = severity => {
    this.props.setSelectedSeverity(severity)

    const currentParams = qs.parse(this.props.location.search)

    this.props.push({
      search: qs.stringify({
        ...currentParams,
        severityCategory: severity,
      }),
    })

    //No reason to refetch the timleine, only want to refetch the heatmap
    this.props.fetchCategoryRollupData({
      severityCategory: severity,
    })
  }

  setFilters = ({
    swimlane,
    selectedResourceId,
    ticketType,
    ticketKey,
    severityCategory,
    view, //eslint-disable-line
    ...rest
  }) => {
    if (selectedResourceId) {
      this.props.setSelectedResourceId(selectedResourceId)
    } else {
      this.props.clearSelectedResourceId()
    }

    this.props.setSelectedSwimlaneName(swimlane)

    let category = ''
    if (ticketType === 'Framework') {
      category = ticketKey
    } else {
      category = ticketType
    }

    this.props.setSelectedCategory(category)

    this.props.setVisFilters({
      ...rest,
      severityCategory: severityCategory || this.props.selectedSeverity,
      ticketKey,
      ticketType,
    })

    this.props.push({
      search: qs.stringify({
        swimlane: swimlane,
        category: category,
        selectedResourceId: selectedResourceId,
        status: this.props.status,
        view: VIEWS.RESOURCES,
        severityCategory: severityCategory || this.props.selectedSeverity,
        ...rest,
      }),
    })
  }

  setSelectedResourceId = resourceId => {
    this.props.setSelectedResourceId(resourceId)
    const currentParams = qs.parse(this.props.location.search)
    this.props.push({
      search: qs.stringify({
        ...currentParams,
        selectedResourceId: resourceId,
      }),
    })
  }

  setVisFilters = filters => {
    this.props.setVisFilters(filters)

    this.props.push({
      search: qs.stringify({
        view: VIEWS.RESOURCES,
        ...filters,
      }),
    })
  }

  toggleView = newView => {
    this.props.clearSelectedResourceId()
    this.props.setSelectedSwimlaneName()
    this.props.setSelectedCategory()
    this.props.setSelectedSeverity()
    this.props.setVisFilters({})
    this.props.setStatusFilter('OPEN')

    //Dont include the severityCategory because we wiped it above
    this.props.fetchRollupData()

    this.props.push({
      search: qs.stringify({
        view: newView,
      }),
    })
  }

  getFilters = () => {
    const baseFilters = this.props.visFilters
      .set('severityCategory', this.props.selectedSeverity)
      .set('status', this.props.status)

    return baseFilters
  }

  renderVisFilters = () => {
    const titleParts = []

    if (this.props.selectedSwimlaneName) {
      titleParts.push(
        <Pill style={this.styles.preFilterPill}>
          Swimlane: {this.props.selectedSwimlaneName}{' '}
        </Pill>
      )
    }

    if (this.props.selectedCategory) {
      if (this.props.selectedCategory.includes('srn')) {
        const srn = this.props.selectedCategory
        const name = this.props.controlGroups.getIn([srn, 'title'])
        titleParts.push(
          <Pill style={this.styles.preFilterPill}>
            Control Framework: {name}{' '}
          </Pill>
        )
      } else {
        titleParts.push(
          <Pill style={this.styles.preFilterPill}>
            CRM Category : {this.props.selectedCategory}{' '}
          </Pill>
        )
      }
    }

    if (titleParts.length === 0) {
      return null
    }

    return (
      <CenterContent>
        <div style={{ margin: '0.5em 0' }}>{titleParts} </div>
      </CenterContent>
    )
  }

  getHelpIcon = () => (
    <div style={{ padding: '2px', marginLeft: '0.4rem' }}>
      <Tooltip
        trigger={'hover'}
        anchor={
          <BorderlessButton>
            <Icon
              fa
              name="help"
              color={this.props.theme.neutralMedium}
              style={{ fontSize: '19px' }}
            />
          </BorderlessButton>
        }
        tooltipContent={
          <div style={this.styles.tooltip}>
            <span>
              The Security Center allows users to organize and group cloud
              assets and resources by account, tags and resource names, across
              multiple cloud accounts and cloud platforms. Select “Manage
              Swimlanes” from the user menu on the right to configure Swimlanes.
            </span>
            <TextLink
              style={{ margin: '5px 0px 5px 0px' }}
              color="primary"
              onClick={this.toggleSCInfoModal}
            >
              Read More
            </TextLink>
          </div>
        }
        position="right"
      />
    </div>
  )

  handleFilterStatus = status => {
    this.setState({
      dropdown: false,
    })
    this.props.setStatusFilter(status)
  }

  getDropDownItems = () => {
    const STATUS = ['OPEN', 'NEW', 'ALL', 'CLOSED']
    return STATUS.map((item, index) => (
      <DropdownItem
        key={index}
        title={item}
        onClick={() => this.handleFilterStatus(item)}
        disabled={this.props.status === item}
      >
        {' '}
        {item}{' '}
      </DropdownItem>
    ))
  }

  getStatusFilter = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div
          style={{ fontSize: '1.1rem', fontWeight: '400', marginTop: '-2px' }}
        >
          {' '}
          Filter by Status:
        </div>
        <DropdownActions
          disabled={this.props.loadingRollupData.get('category')}
          isOpen={this.state.dropdown}
          toggle={() =>
            this.setState(oldState => ({
              dropdown: !oldState.dropdown,
            }))
          }
        >
          <DropdownAnchor
            borderless
            style={{ padding: '0 0.5em', display: 'flex', width: '75px' }}
          >
            <div style={{ margin: '0rem 0.75rem 0rem 0rem', fontSize: '1rem' }}>
              {this.props.status}
            </div>
            <Icon fa name="caret-down" />
          </DropdownAnchor>
          <DropdownMenu>{this.getDropDownItems()}</DropdownMenu>
        </DropdownActions>
      </div>
    )
  }

  renderVis = () => {
    return (
      <div style={this.styles.content}>
        <div style={this.styles.pageTitle}>
          <div style={{ display: 'flex' }}>
            <SectionHeader>Tickets Overview</SectionHeader>
            {this.getHelpIcon()}
          </div>
        </div>
        {this.getStatusFilter()}
        <OverviewVis
          setSeverity={this.setSeverity}
          setFilters={this.setFilters}
          visFilters={this.getFilters()}
          resizing={this.state.resizing}
        />
      </div>
    )
  }

  renderBoard = () => {
    return (
      <div style={this.styles.content}>
        <div style={this.styles.pageTitle}>
          <div style={{ display: 'flex' }}>
            <SectionHeader>Ticket Resources</SectionHeader>
            {this.getHelpIcon()}
          </div>

          <div style={this.styles.dateFilter}>{this.renderVisFilters()}</div>

          <div style={this.styles.actionButtons}>
            <Button
              outline
              color="primary"
              onClick={() => this.toggleView(VIEWS.VIS)}
            >
              Back to Heatmap Summary
            </Button>
          </div>
          <br />
        </div>

        <FilterVis
          location={this.props.location}
          visFilters={this.getFilters()}
          setSelectedResourceId={this.setSelectedResourceId}
          setDateFilter={this.setDateRange}
          selectedResource={this.props.selectedResource}
        />
      </div>
    )
  }

  renderView = () => {
    if (this.props.view === VIEWS.VIS) {
      return this.renderVis()
    } else {
      return this.renderBoard()
    }
  }

  render() {
    if (!this.hasPermission) {
      return (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {' '}
          Insufficent Permissions to view data in the Security Center{' '}
        </div>
      )
    }

    if (
      !this.props.swimlanes ||
      !this.props.loadedControlGroups ||
      !this.props.loadedPolicies
    ) {
      return <LoadingAnim />
    }

    const isOpen =
      !this.props.userProfile.get('hasSeenSecurityCenterWelcomeModal') ||
      this.props.scInfoModal

    return (
      <Fragment>
        {this.renderView()}

        <Modal
          isOpen={isOpen}
          toggle={this.handleWelcomeModal}
          style={{ minWidth: '75vw' }}
        >
          <ModalBody>
            <ScWelcomeScreen />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.handleWelcomeModal}>
              Get Started!
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    )
  }
}

SecurityCenter.propTypes = {
  clearSelectedResourceId: PropTypes.func.isRequired,
  controlGroups: ImmutablePropTypes.iterable.isRequired,
  fetchCategoryRollupData: PropTypes.func.isRequired,
  fetchRollupData: PropTypes.func,
  loadedControlGroups: PropTypes.bool,
  loadedPolicies: PropTypes.bool,
  location: PropTypes.object,
  userProfile: ImmutablePropTypes.iterable,
  push: PropTypes.func,
  setSecurityCenterWelcomeModal: PropTypes.func,
  setSelectedCategory: PropTypes.func.isRequired,
  setSelectedResourceId: PropTypes.func.isRequired,
  setSelectedSeverity: PropTypes.func.isRequired,
  setSelectedSwimlaneName: PropTypes.func.isRequired,
  setVisFilters: PropTypes.func.isRequired,
  selectedCategory: PropTypes.string,
  selectedSwimlaneName: PropTypes.string,
  selectedSeverity: PropTypes.string,
  swimlanes: ImmutablePropTypes.map,
  view: PropTypes.string,
  visFilters: ImmutablePropTypes.map.isRequired,
  theme: themeShape,
  toggleSCInfoModal: PropTypes.func,
  scInfoModal: PropTypes.bool,
  selectedResource: ImmutablePropTypes.iterable,
  userHasPermission: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  setStatusFilter: PropTypes.func.isRequired,
  loadingRollupData: ImmutablePropTypes.map,
}

const mapStateToProps = createStructuredSelector({
  controlGroups: selectControlGroups,
  loadedControlGroups: selectLoadedControlGroups,
  loadedPolicies: selectLoadedPolicies,
  selectedResourceId: selectSelectedResourceId,
  selectedSwimlaneName: selectSelectedSwimlaneName,
  selectedCategory: selectSelectedCategory,
  selectedSeverity: selectSelectedSeverity,
  swimlanes: selectSwimlanes,
  userProfile: selectUserProfile,
  view: selectView,
  visFilters: selectVisFilters,
  scInfoModal: selectSCInfoModal,
  selectedResource: selectSelectedResource,
  status: selectStatus,
  loadingRollupData: selectLoadingRollupData,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      clearSelectedResourceId,
      fetchRollupData,
      fetchCategoryRollupData,
      setSecurityCenterWelcomeModal,
      setSelectedResourceId,
      setSelectedSwimlaneName,
      setSelectedCategory,
      setVisFilters,
      push,
      toggleSCInfoModal,
      setSelectedSeverity,
      setStatusFilter,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
const withReducer = injectReducer({ key: 'securityCenter', reducer })
const withSaga = injectSaga({ key: 'sc', saga: scSaga })

export default compose(
  withReducer,
  withSaga,
  withConnect,
  permissionChecker
)(themeable(SecurityCenter))
