import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import _ from 'lodash'
import ApexChart from 'react-apexcharts'

import { exists } from 'utils/sonraiUtils'
import BorderlessButton from 'components/BorderlessButton'
import BounceLoadingAnimation from 'components/BounceLoadingAnimation'
import CenterContent from 'components/CenterContent'
import EmptyWidgetResults from 'components/EmptyWidgetResults'
import ChartLegendEntry from 'components/ChartLegendEntry'
import { stripTags } from 'utils/sonraiUtils'
export class StackedBarTimeSeries extends React.Component {
  styles = {
    hiddenLegend: {
      color: '#ccc',
      cursor: 'pointer',
    },
    legend: {
      color: '#000',
    },
  }

  constructor(props) {
    super(props)

    const categories = this.getCategories(props.data)
    this.state = {
      categories: categories,
      series: this.getData(props.data, categories),
      focusedSeries: null,
    }

    this.chart = null
  }

  onMount = chart => {
    this.chart = chart
    if (this.props.preSelectedSeries) {
      this.state.series.forEach((series, index) => {
        if (series.name !== this.props.preSelectedSeries) {
          chart.hideSeries(series.name)
        } else {
          this.setState({
            focusedSeries: index,
          })
        }
      })
    }
  }

  componentDidUpdate(oldProps) {
    if (!_.isEqual(oldProps.data, this.props.data)) {
      const categories = this.getCategories(this.props.data)

      this.setState({
        categories: categories,
        series: this.getData(this.props.data, categories),
      })
    }
  }

  onClickLegend = seriesIndex => {
    this.chart.resetSeries()

    if (this.state.focusedSeries !== seriesIndex) {
      this.state.series.forEach((series, index) => {
        if (index !== seriesIndex) {
          this.chart.hideSeries(series.name)
        }
      })
    }

    this.setState(currentState => {
      const newFocusedSeries =
        currentState.focusedSeries === seriesIndex ? null : seriesIndex

      if (this.props.onClickBar) {
        if (newFocusedSeries !== null) {
          this.props.onClickBar({
            seriesIndex,
            series: currentState.series[seriesIndex],
          })
        } else {
          this.props.onClickBar({
            seriesIndex: null,
            series: null,
          })
        }
      }

      return {
        focusedSeries: newFocusedSeries,
      }
    })
  }

  getData = (data, swimlaneTitles) => {
    return data.map(series => {
      const seriesCopy = { ...series }
      const dataMappedToSwimlaneSRNs = series.data

      seriesCopy.data = swimlaneTitles.map(swimlaneTitle => {
        const simwlaneSrn = this.props.swimlanes
          .get(swimlaneTitle, Map())
          .get('srn')
        return dataMappedToSwimlaneSRNs[simwlaneSrn] || 0
      })

      return seriesCopy
    })
  }

  getCategories = data => {
    const dataSetKeys = data
      .map(({ data }) => data)
      .map(obj => Object.keys(obj))
    const swimlaneSRNs = _.chain(dataSetKeys).flatten().uniq().value()

    const swimlaneReverseLookupMap = _.keyBy(
      this.props.swimlanes.valueSeq().toJS(),
      'srn'
    )

    return swimlaneSRNs
      .map(srn => {
        const swimlane = swimlaneReverseLookupMap[srn]
        if (!swimlane) {
          console.warn(`found ticket for delted swimlane : ${srn}`) // eslint-disable-line no-console
        }
        return swimlane?.title ?? false // eslint-disable-line no-undef
      })
      .filter(_.identity)
  }

  render() {
    if (this.props.loading) {
      return (
        <CenterContent style={{ height: `${this.props.height}px` }}>
          <BounceLoadingAnimation />
        </CenterContent>
      )
    }

    const { series } = this.state

    if (!series || series.length === 0) {
      return (
        <div style={{ height: `${this.props.height}px` }}>
          <EmptyWidgetResults />
        </div>
      )
    }

    return (
      <div
        style={{
          height: `${this.props.height}px`,
          overflow: 'hidden',
          display: 'grid',
          gridTemplateColumns:
            this.props.legendPosition === 'right' ? '1fr 145px' : '145px 1fr',
          gridTemplateAreas:
            this.props.legendPosition === 'right'
              ? '"chart legend'
              : '"legend chart',
          ...this.props.style,
        }}
      >
        <div style={{ gridArea: 'legend', paddingTop: '25px' }}>
          {this.state.series.map((series, index) => (
            <div key={series.id}>
              <BorderlessButton
                onClick={() => this.onClickLegend(index)}
                style={
                  exists(this.state.focusedSeries) &&
                  this.state.focusedSeries !== index
                    ? this.styles.hiddenLegend
                    : this.styles.legend
                }
              >
                <ChartLegendEntry
                  title={series.name}
                  color={this.props.colors[index]}
                />
              </BorderlessButton>
            </div>
          ))}
        </div>
        <ApexChart
          key="barchart"
          options={{
            chart: {
              stacked: true,
              toolbar: {
                show: false,
              },
              zoom: {
                enabled: true,
              },
              events: {
                zoomed: this.onSelectAndZoom,
                mounted: this.onMount,
              },
              selection: {
                enabled: true,
                type: 'x',
              },
            },
            dataLabels: {
              enabled: false,
            },
            plotOptions: {
              bar: {
                horizontal: false,
                shadeIntensity: 0.1,
              },
            },
            colors: this.props.colors,
            xaxis: {
              type: 'category',
              categories: stripTags(this.state.categories),
            },
            legend: {
              show: false,
            },
            fill: {
              opacity: 1,
            },
          }}
          series={this.state.series}
          type="bar"
          height={this.props.height}
        />
      </div>
    )
  }
}

StackedBarTimeSeries.defaultProps = {
  height: 350,
  legendPosition: 'right',
  style: {},
}

StackedBarTimeSeries.propTypes = {
  colors: PropTypes.array,
  data: PropTypes.array,
  height: PropTypes.number,
  loading: PropTypes.bool,
  onClickBar: PropTypes.func,
  legendPosition: PropTypes.string,
  style: PropTypes.object,
  preSelectedSeries: PropTypes.string,
  swimlanes: ImmutablePropTypes.map,
}

export default StackedBarTimeSeries
