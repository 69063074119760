import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { Map } from 'immutable'

import BorderlessButton from 'components/BorderlessButton'
import Scrollable from 'components/Scrollable'
import Icon from 'components/Icon'
import RapSheet from 'containers/RapSheet'
import BorderedCard from 'components/BorderedCard'
import SectionHeader from 'components/SectionHeader'
import { getNameFromSrn } from 'utils/sonraiUtils'
import { getNodeViewPushParams } from 'utils/sonraiUtils'
import { getTypeFromSrn } from 'utils/graphDataUtils'
import {
  selectResources,
  selectResourcesLoading,
} from 'containers/RapSheet/selectors'
import {
  disableResourceMonitoring,
  enableResourceMonitoring,
  setVisFilters,
} from './actions'
import {
  selectSelectedResourceId,
  selectSelectedSwimlaneName,
  selectSelectedCategory,
} from './selectors'
import ResourcesList from './ResourcesList'
export class FilterVis extends React.Component {
  constructor(props) {
    super(props)

    this.styles = {
      base: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        gridTemplateAreas: '"listcolumn resource"',
        overflow: 'hidden',
        gridColumnGap: '0.5em',
      },
      listcolumn: {
        gridArea: 'listcolumn',
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        gridTemplateAreas: '"header" "list"',
        overflow: 'hidden',
        width: '395px',
      },
      resourcelist: {
        gridArea: 'list',
      },
      resource: {
        gridArea: 'resource',
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        gridTemplateAreas: '"header" "rapsheetcontent"',
        overflow: 'hidden',
      },
      header: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        gridTemplateAreas: '"title close"',
        gridArea: 'header',
      },
      title: {
        gridArea: 'title',
      },
      preFilterPill: {
        marginRight: '1em',
      },
    }
  }

  onFilterChange = filters => {
    let newFilters = { ...filters }

    this.props.setVisFilters(newFilters)
  }

  getTitle = () => {
    if (!this.props.selectedResourceId) {
      return null
    }

    const resource =
      this.props.resources.get(this.props.selectedResourceId) || Map()

    if (resource.isEmpty()) {
      return null
    }

    const nav = getNodeViewPushParams(
      this.props.selectedResourceId,
      getTypeFromSrn(this.props.selectedResourceId)
    )

    const name =
      resource.get('friendlyName') ||
      resource.get('name') ||
      getNameFromSrn(this.props.selectedResourceId)

    return (
      <div>
        Security Summary for Resource: <Link to={nav}>{name}</Link>
      </div>
    )
  }

  render() {
    const size = this.props.resources.size
    return (
      <div style={this.styles.base}>
        <div style={this.styles.listcolumn}>
          <SectionHeader small>
            {this.props.resourcesLoading
              ? 'Loading Resources...'
              : size >= 100
              ? `Showing the top 100 Resources`
              : `Resources (${this.props.resources.size})`}
          </SectionHeader>
          <Scrollable style={this.styles.resourcelist}>
            <ResourcesList
              resources={this.props.resources.toList()}
              setSelectedResourceId={this.props.setSelectedResourceId}
              selectedResourceId={this.props.selectedResourceId}
              loading={this.props.resourcesLoading}
              disableResourceMonitoring={this.props.disableResourceMonitoring}
              enableResourceMonitoring={this.props.enableResourceMonitoring}
            />
          </Scrollable>
        </div>

        <BorderedCard style={this.styles.resource}>
          <div style={this.styles.header}>
            <SectionHeader small style={this.styles.title}>
              {this.getTitle()}
            </SectionHeader>
            <div style={this.styles.close}>
              {this.props.selectedResourceId && (
                <BorderlessButton
                  onClick={() => this.props.setSelectedResourceId(null)}
                  title="Clear selected resource"
                >
                  <Icon fa name="times" />
                </BorderlessButton>
              )}
            </div>
          </div>
          <div style={{ height: '100%', overflow: 'hidden' }}>
            <RapSheet
              key={
                this.props.selectedResourceId
                  ? this.props.selectedResourceId
                  : 'category'
              }
              location={this.props.location}
              resourceId={this.props.selectedResourceId}
              selectedSwimlaneName={this.props.selectedSwimlaneName}
              onFilterChange={this.onFilterChange}
              preFilters={this.props.visFilters}
              setDateFilter={this.setDateFilter}
              selectedCategory={this.props.selectedCategory}
            />
          </div>
        </BorderedCard>
      </div>
    )
  }
}

FilterVis.propTypes = {
  disableResourceMonitoring: PropTypes.func.isRequired,
  enableResourceMonitoring: PropTypes.func.isRequired,
  resourcesLoading: PropTypes.bool,
  resources: ImmutablePropTypes.iterable.isRequired,
  selectedResourceId: PropTypes.string,
  selectedCategory: PropTypes.string,
  selectedSwimlaneName: PropTypes.string,
  setSelectedResourceId: PropTypes.func.isRequired,
  visFilters: ImmutablePropTypes.map.isRequired,
  setVisFilters: PropTypes.func.isRequired,
  location: PropTypes.any,
}

const mapStateToProps = createStructuredSelector({
  resources: selectResources,
  resourcesLoading: selectResourcesLoading,
  selectedResourceId: selectSelectedResourceId,
  selectedSwimlaneName: selectSelectedSwimlaneName,
  selectedCategory: selectSelectedCategory,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      disableResourceMonitoring,
      enableResourceMonitoring,
      setVisFilters,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(FilterVis)
