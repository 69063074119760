import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { RectangleShimmer } from 'components/ShimmerLoader'
import SecurityCenterResourceCard from 'components/SecurityCenterResourceCard'

export class ResourcesList extends React.Component {
  handleResourceSelect = resource => {
    this.props.setSelectedResourceId(resource.srn)
  }

  handleDisableMonitoring = resource => {
    this.props.disableResourceMonitoring(resource.srn)
  }

  handleEnableMonitoring = resource => {
    const { srn } = resource
    this.props.enableResourceMonitoring(srn)
  }

  renderResource = resource => {
    return (
      <SecurityCenterResourceCard
        key={resource.get('srn')}
        resource={resource.toJS()}
        selectCard={this.handleResourceSelect}
        selectedResourceSrn={this.props.selectedResourceId}
        disableMonitoring={this.handleDisableMonitoring}
        enableMonitoring={this.handleEnableMonitoring}
        sortProperty={this.props.sortProperty}
      />
    )
  }

  render() {
    const resources = this.props.resources.sort((a, b) => {
      return b.get('highestAlertSeverity') - a.get('highestAlertSeverity')
    })

    if (this.props.loading) {
      return (
        <div style={{ padding: '10px', width: '395px' }}>
          <RectangleShimmer width={360} height={130} />
          <br />
          <RectangleShimmer width={360} height={130} />
          <br />
          <RectangleShimmer width={360} height={130} />
          <br />
          <RectangleShimmer width={360} height={130} />
        </div>
      )
    }

    return (
      <div style={{ width: '395px' }}>{resources.map(this.renderResource)}</div>
    )
  }
}

ResourcesList.propTypes = {
  loading: PropTypes.bool,
  resources: ImmutablePropTypes.list.isRequired,
  disableResourceMonitoring: PropTypes.func.isRequired,
  enableResourceMonitoring: PropTypes.func,
  setSelectedResourceId: PropTypes.func.isRequired,
  selectedResourceId: PropTypes.string,
  sortProperty: PropTypes.string,
}

export default ResourcesList
