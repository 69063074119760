import React from 'react'
import PropTypes from 'prop-types'
import { XYPlot, XAxis, YAxis, HeatmapSeries, Hint } from 'react-vis'
import color from 'color'
import { scaleLinear } from 'd3-scale'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'

import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import ChartTooltip from 'components/ChartTooltip'

export class HeatMap extends React.Component {
  constructor(props) {
    super(props)

    this.emptyColor = color(props.theme.success)
      .desaturate(0.6)
      .lighten(0.9)
      .lighten(0.1)
      .hex()
  }

  state = {
    hoveredData: null,
    hoveredSeries: null,
    hoveredCategory: null,
    hoveringHint: false,
  }

  onClickCategoryLabel = value => {
    this.props.onClick({
      x: value,
      y: undefined,
    })
  }

  onClickSeriesLabel = value => {
    this.props.onClick({
      x: undefined,
      y: value,
    })
  }

  formatXTick = value => {
    const name = this.props.categoryNameMap.get(value) || value
    let trimmedValue = name || ''
    if (trimmedValue.length > 13) {
      trimmedValue = trimmedValue.substr(0, 14 - 1) + '…'
    }

    return (
      <text
        onClick={() => this.onClickCategoryLabel(value)}
        textAnchor="middle"
        transform="translate(0, 40) rotate(-45)"
        onMouseEnter={() => this.setState({ hoveredCategory: value })}
        onMouseLeave={() => this.setState({ hoveredCategory: null })}
        style={{ cursor: 'pointer' }}
      >
        {trimmedValue}
      </text>
    )
  }

  formatYTick = value => {
    let trimmedValue = value || ''
    if (trimmedValue.length > 16) {
      trimmedValue = value.substr(0, 15) + '…'
    }

    return (
      <text
        onClick={() => this.onClickSeriesLabel(value)}
        dy="0.32em"
        textAnchor="end"
        transform="translate(-15, 0)"
        onMouseEnter={() => this.setState({ hoveredSeries: value })}
        onMouseLeave={() => this.setState({ hoveredSeries: null })}
        style={{ cursor: 'pointer' }}
      >
        {trimmedValue}
      </text>
    )
  }

  getVerticalBarData = () => {
    let yAxisTotals = []

    if (this.props.seriesTotalsOverrides) {
      yAxisTotals = this.props.data.map(series => {
        const total = this.props.seriesTotalsOverrides[series.name]
        return {
          x: total,
          y: series.name,
          color: this.props.theme.secondary,
        }
      })
    } else {
      yAxisTotals = this.props.data.map(series => {
        const total = series.data.reduce((seriesTotal, config) => {
          return (seriesTotal += config.y)
        }, 0)
        return {
          x: total,
          y: series.name,
          color: this.props.theme.secondary,
        }
      })
    }

    if (this.props.colorType === 'range') {
      const max = yAxisTotals.reduce(
        (max, val) => Math.max(max, val.x),
        -Infinity
      )

      const colorRange = scaleLinear().domain([0, max]).range(this.props.colors)

      return yAxisTotals.map(val => {
        val.color =
          val.x === 0
            ? '#fff'
            : color(colorRange(val.x)).alpha(0.5).rgb().string()
        return val
      })
    }

    return yAxisTotals
  }

  getHorizontalBarData = () => {
    let xAxisTotals = []

    if (this.props.categoryTotalsOverrides) {
      xAxisTotals = this.props.xValues.map(val => {
        const total = this.props.categoryTotalsOverrides[val]
        return {
          y: total > 0 ? total : 0,
          x: val,
          color: this.props.theme.secondary,
          displayValue: total,
        }
      })
    } else {
      xAxisTotals = this.props.xValues
        .map((val, index) => {
          const total = this.props.data.reduce((total, series) => {
            return (total += series.data[index].y)
          }, 0)

          return total
        })
        .map((total, index) => ({
          y: total,
          x: this.props.xValues[index],
          color: this.props.theme.secondary,
        }))
    }

    if (this.props.colorType === 'range') {
      const max = xAxisTotals.reduce(
        (max, val) => Math.max(max, val.y),
        -Infinity
      )

      const colorRange = scaleLinear().domain([0, max]).range(this.props.colors)

      return xAxisTotals.map(val => {
        val.color =
          val.y === 0
            ? '#fff'
            : color(colorRange(val.y)).alpha(0.5).rgb().string()
        return val
      })
    }

    return xAxisTotals
  }

  getHeatmapData = () => {
    let vals = []

    this.props.data.forEach((series, seriesIndex) => {
      vals = vals.concat(
        series.data.map((conf, index) => ({
          count: conf.y === 0 ? 100 : conf.y, // server side riskScoreNumeric scale is from 1 - 100 so if our count is 0 it means there is no tickets and we can make the risk score 100
          y: series.name,
          x: this.props.xValues[index],
          displayValue: conf.displayValue,
          color:
            this.props.colorType === 'series' && this.props.colors
              ? this.props.colors[seriesIndex]
              : this.props.theme.primary,
        }))
      )
    })

    const mainVals = vals.filter(
      square => square.y !== this.props.totalSeriesName
    )
    const max = mainVals.reduce(
      (max, row) => Math.max(max, row.count),
      -Infinity
    )

    if (this.props.colorType === 'range') {
      const colorRange = scaleLinear()
        .domain([0, 50, 70, 99, 100])
        .range(['#bc1818', '#bc1818', '#fc7b1e', '#fdd13a', '#81BC88'])
      return vals.map(conf => {
        conf.color = conf.count === 0 ? this.emptyColor : colorRange(conf.count)
        if (
          this.state.hoveredCategory &&
          conf.x !== this.state.hoveredCategory
        ) {
          conf.color = color(conf.color).alpha(0.5).rgb().string()
        }
        if (this.state.hoveredSeries && conf.y !== this.state.hoveredSeries) {
          conf.color = color(conf.color).alpha(0.5).rgb().string()
        }
        if (conf.count === 0) {
          // figuring this one out
        }
        if (conf.count < 0) {
          conf.color = `rgb(225,225,225)`
        }
        return conf
      })
    } else {
      const opacityRange = scaleLinear().domain([0, max]).range([0, 1])

      return vals.map(conf => {
        conf.color =
          conf.count === 0
            ? this.emptyColor
            : color(conf.color).alpha(opacityRange(conf.count)).rgb().string()

        return conf
      })
    }
  }

  onValueMouseOverHeatmap = datapoint => {
    this.setState({
      hoveredData: datapoint,
    })
  }

  onValueMouseOutHeatmap = () => {
    if (!this.state.hoveringHint) {
      this.setState({
        hoveredData: null,
      })
    }
  }

  onHoverHint = () => {
    this.setState({
      hoveringHint: true,
    })
  }

  onLeaveHint = () => {
    this.setState({
      hoveredData: null,
      hoveredSeries: null,
      hoveredCategory: null,
      hoveringHint: false,
    })
  }

  onValueClickHeatmap = datapoint => {
    this.props.onClick({
      x: datapoint.x,
      y: datapoint.y,
    })
  }

  render() {
    const seriesTotals = this.getVerticalBarData()
    const categoryTotals = this.getHorizontalBarData()

    const xAxisHoverValue =
      categoryTotals.find(total => total.x === this.state.hoveredCategory) || {}

    const yAxisHoverValue =
      seriesTotals.find(total => total.y === this.state.hoveredSeries) || {}

    return (
      <div>
        <XYPlot
          xType="ordinal"
          xDomain={this.props.xValues}
          yType="ordinal"
          yDomain={this.props.data.map(series => series.name)}
          height={this.props.height}
          width={this.props.width}
          margin={{ right: 0, top: 0, left: 170, bottom: 150 }}
        >
          <XAxis orientation="bottom" tickFormat={this.formatXTick} />
          <YAxis tickFormat={this.formatYTick} />
          <HeatmapSeries
            colorType="literal"
            style={{
              stroke: this.props.theme.background,
              strokeWidth: '2px',
              cursor: 'pointer',
            }}
            data={this.getHeatmapData()}
            onValueMouseOver={this.onValueMouseOverHeatmap}
            onValueMouseOut={this.onValueMouseOutHeatmap}
            onValueClick={this.onValueClickHeatmap}
          />
          {this.state.hoveredData && (
            <Hint value={this.state.hoveredData}>
              <ChartTooltip
                onHover={this.onHoverHint}
                onEndHover={this.onLeaveHint}
                color={this.state.hoveredData.color}
                title={
                  this.state.hoveredData.x === 'Access' ||
                  this.state.hoveredData.x === 'Activity' ||
                  this.state.hoveredData.x === 'Property'
                    ? `CRM - ${
                        this.props.categoryNameMap.get(
                          this.state.hoveredData.x
                        ) || this.state.hoveredData.x
                      }`
                    : `Control Framework - ${
                        this.props.categoryNameMap.get(
                          this.state.hoveredData.x
                        ) || this.state.hoveredData.x
                      }`
                }
                label={`${this.state.hoveredData.y} (Risk Grade)`}
                value={
                  this.state.hoveredData.count >= 0
                    ? this.state.hoveredData.displayValue ||
                      this.state.hoveredData.count
                    : 'Not Enabled'
                }
              />
            </Hint>
          )}
          {this.state.hoveredSeries && (
            <Hint
              value={{
                y: this.state.hoveredSeries,
                x: this.props.xValues[0],
              }}
              align={{ horizontal: 'left', vertical: 'auto' }}
            >
              <ChartTooltip
                onHover={this.onHoverHint}
                onEndHover={this.onLeaveHint}
                title={`Swimlane - ${this.state.hoveredSeries}`}
                label="Risk Grade"
                value={
                  yAxisHoverValue.displayValue
                    ? yAxisHoverValue.displayValue
                    : yAxisHoverValue.x
                }
              />
            </Hint>
          )}
          {this.state.hoveredCategory && (
            <Hint
              value={{
                x: this.state.hoveredCategory,
                y: this.props.data[0].name,
              }}
              align={{ horizontal: 'auto', vertical: 'bottom' }}
            >
              <ChartTooltip
                onHover={this.onHoverHint}
                onEndHover={this.onLeaveHint}
                title={
                  this.state.hoveredCategory === 'Access' ||
                  this.state.hoveredCategory === 'Activity' ||
                  this.state.hoveredCategory === 'Property'
                    ? `CRM - ${
                        this.props.categoryNameMap.get(
                          this.state.hoveredCategory
                        ) || this.state.hoveredCategory
                      }`
                    : `Control Framework - ${
                        this.props.categoryNameMap.get(
                          this.state.hoveredCategory
                        ) || this.state.hoveredCategory
                      }`
                }
                label="Risk Grade"
                value={xAxisHoverValue.displayValue || xAxisHoverValue.y}
              />
            </Hint>
          )}
        </XYPlot>
      </div>
    )
  }
}

HeatMap.defaultProps = {
  height: 350,
  width: 800,
  colorType: 'series',
  categoryNameMap: Map(),
}

HeatMap.propTypes = {
  colors: PropTypes.array,
  colorType: PropTypes.oneOf(['series', 'range']),
  height: PropTypes.number,
  xValues: PropTypes.array,
  data: PropTypes.array,
  onClick: PropTypes.func,
  theme: themeShape,
  totalSeriesName: PropTypes.string,
  seriesTotalsOverrides: PropTypes.object,
  categoryTotalsOverrides: PropTypes.object,
  width: PropTypes.number,
  categoryNameMap: ImmutablePropTypes.map,
}

export default themeable(HeatMap)
