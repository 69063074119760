/*
 *
 * SecurityCenter reducer
 *
 */

import { fromJS } from 'immutable'
import {
  SET_SELECTED_RESOURCE_ID,
  SET_SELECTED_RESOURCE,
  CLEAR_SELECTED_RESOURCE_ID,
  SET_FILTER,
  SET_SELECTED_SWIMLANE_NAME,
  VIEWS,
  SET_CATEGORY_ROLLUP_DATA,
  SET_TIMELINE_ROLLUP_DATA,
  FETCH_ROLLUP_DATA,
  SET_VIS_FILTERS,
  SET_SELECTED_CATEGORY,
  TOGGLE_SC_INFO_MODAL,
  SET_SELECTED_SEVERITY,
  BULK_DISABLE_MONITORING_SUCCESS,
  BULK_DISABLE_MONITORING,
  SET_STATUS_FILTER,
} from './constants'
import { handleActions } from 'redux-actions'

const initialState = fromJS({
  bulkDisablingMonitoring: false,
  resources: undefined,
  selectedResourceId: undefined,
  selectedSwimlaneName: undefined,
  selectedCategory: undefined,
  selectedSeverity: undefined,
  filters: {
    severity: [],
    cloud: [],
    sort: { label: 'Severity', value: 'severity' },
    activeFilter: { label: 'Only Active Resources', value: 'onlyAR' },
  },
  view: VIEWS.VIS,
  categoryRollupData: [],
  categorySeriesTotals: {},
  timelineRollupData: [],
  loadingRollupData: fromJS({ category: false, timeline: false }),
  visFilters: {},
  scInfoModal: false,
  selectedResource: {
    resource: {},
    isLoading: false,
  },
  status: 'OPEN',
})

const securityCenterReducer = handleActions(
  {
    [BULK_DISABLE_MONITORING]: state =>
      state.set('bulkDisablingMonitoring', true),
    [BULK_DISABLE_MONITORING_SUCCESS]: state =>
      state.set('bulkDisablingMonitoring', false),
    [FETCH_ROLLUP_DATA]: state =>
      state
        .setIn(['loadingRollupData', 'category'], true)
        .setIn(['loadingRollupData', 'timeline'], true),
    [SET_CATEGORY_ROLLUP_DATA]: (state, { payload }) =>
      state
        .setIn(['loadingRollupData', 'category'], false)
        .set('categoryRollupData', fromJS(payload.rollup))
        .set('categorySeriesTotals', fromJS(payload.totals)),
    [SET_TIMELINE_ROLLUP_DATA]: (state, { payload }) =>
      state
        .setIn(['loadingRollupData', 'timeline'], false)
        .set('timelineRollupData', fromJS(payload)),
    [SET_FILTER]: (state, { payload }) =>
      state.setIn(['filters', payload.filterType], fromJS(payload.value)),
    [SET_SELECTED_RESOURCE_ID]: (state, { payload }) => {
      if (payload) {
        return state
          .set('selectedResourceId', payload)
          .setIn(['selectedResource', 'isLoading'], true)
      } else {
        return state
          .set('selectedResourceId', payload)
          .set('selectedResource', initialState.get('selectedResource'))
      }
    },
    [SET_SELECTED_RESOURCE]: (state, { payload }) =>
      state
        .setIn(['selectedResource', 'isLoading'], false)
        .setIn(['selectedResource', 'resource'], fromJS(payload)),

    [SET_SELECTED_SWIMLANE_NAME]: (state, { payload }) =>
      state.set('selectedSwimlaneName', payload),
    [SET_SELECTED_CATEGORY]: (state, { payload }) =>
      state.set('selectedCategory', payload),
    [SET_SELECTED_SEVERITY]: (state, { payload }) =>
      state.set('selectedSeverity', payload),
    [SET_STATUS_FILTER]: (state, { payload }) => state.set('status', payload),
    [CLEAR_SELECTED_RESOURCE_ID]: state =>
      state
        .set('selectedResourceId', undefined)
        .set('selectedSwimlaneName', undefined)
        .set('selectedResource', initialState.get('selectedResource')),
    [SET_VIS_FILTERS]: (state, { payload }) =>
      state.set('visFilters', fromJS(payload)),
    [TOGGLE_SC_INFO_MODAL]: state =>
      state.set('scInfoModal', !state.get('scInfoModal')),
  },
  initialState
)

export default securityCenterReducer
