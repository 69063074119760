import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { XYPlot, XAxis, YAxis, HeatmapSeries } from 'react-vis'
import color from 'color'
import { scaleLinear } from 'd3-scale'

import themeable, { themeShape } from 'containers/ThemeManager/Themeable'

import HeatMapWithTotals from './HeatMapWithTotals'
export class HeatMap extends React.Component {
  formatXTick = value => {
    if (value.length > 13) {
      value = value.substr(0, 14 - 1) + '…'
    }

    return value
  }

  getHeatmapData = () => {
    let vals = []

    this.props.data.forEach((series, seriesIndex) => {
      vals = vals.concat(
        series.data.map((conf, index) => ({
          count: conf.y,
          y: series.name,
          x: this.props.xValues[index],
          color: this.props.colors[seriesIndex],
        }))
      )
    })

    const max = vals.reduce((max, row) => Math.max(max, row.count), -Infinity)

    const opacityRange = scaleLinear().domain([0, max]).range([0, 1])

    vals = vals.map(conf => {
      conf.color =
        conf.count === 0
          ? '#fff'
          : color(conf.color).alpha(opacityRange(conf.count)).rgb().string()

      return conf
    })

    return vals
  }

  render() {
    if (this.props.showTotals) {
      return <HeatMapWithTotals {...this.props} />
    }

    return (
      <XYPlot
        xType="ordinal"
        xDomain={this.props.xValues}
        yType="ordinal"
        yDomain={this.props.data.map(series => series.name)}
        height={this.props.height}
        width={800}
        margin={{ right: 0, top: 0, left: 150, bottom: 150 }}
      >
        <XAxis
          orientation="bottom"
          tickLabelAngle={-45}
          style={{ fontSize: '13px' }}
          tickFormat={this.formatXTick}
        />
        <YAxis />
        <HeatmapSeries
          colorType="literal"
          style={{
            stroke: this.props.theme.background,
            strokeWidth: '2px',
          }}
          data={this.getHeatmapData()}
        />
      </XYPlot>
    )
  }
}

HeatMap.defaultProps = {
  height: 350,
  showTotals: false,
}

HeatMap.propTypes = {
  colors: PropTypes.array,
  height: PropTypes.number,
  xValues: PropTypes.array,
  data: PropTypes.array,
  onClick: PropTypes.func,
  showTotals: PropTypes.bool,
  theme: themeShape,
  categoryNameMap: ImmutablePropTypes.map,
}

export default themeable(HeatMap)
