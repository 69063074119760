/*
 *
 * SecurityCenter actions
 *
 */

import {
  SET_SELECTED_RESOURCE_ID,
  CLEAR_SELECTED_RESOURCE_ID,
  SET_FILTER,
  SET_SELECTED_SWIMLANE_NAME,
  DISABLE_RESOURCE_MONITORING,
  ENABLE_RESOURCE_MONITORING,
  FETCH_ROLLUP_DATA,
  SET_CATEGORY_ROLLUP_DATA,
  SET_TIMELINE_ROLLUP_DATA,
  SET_VIS_FILTERS,
  SET_SELECTED_CATEGORY,
  TOGGLE_SC_INFO_MODAL,
  SET_SELECTED_SEVERITY,
  FETCH_CATEGORY_ROLLUP_DATA,
  SET_SELECTED_RESOURCE,
  SET_STATUS_FILTER,
} from './constants'
import { createAction } from 'redux-actions'

export const setFilter = createAction(SET_FILTER)
export const clearSelectedResourceId = createAction(CLEAR_SELECTED_RESOURCE_ID)
export const setSelectedResourceId = createAction(SET_SELECTED_RESOURCE_ID)
export const setSelectedSwimlaneName = createAction(SET_SELECTED_SWIMLANE_NAME)

export const disableResourceMonitoring = createAction(
  DISABLE_RESOURCE_MONITORING
)
export const enableResourceMonitoring = createAction(ENABLE_RESOURCE_MONITORING)

export const fetchRollupData = createAction(FETCH_ROLLUP_DATA)
export const fetchCategoryRollupData = createAction(FETCH_CATEGORY_ROLLUP_DATA)
export const setCategoryRollupData = createAction(SET_CATEGORY_ROLLUP_DATA)
export const setTimelineRollupData = createAction(SET_TIMELINE_ROLLUP_DATA)

export const setVisFilters = createAction(SET_VIS_FILTERS)
export const setSelectedCategory = createAction(SET_SELECTED_CATEGORY)
export const toggleSCInfoModal = createAction(TOGGLE_SC_INFO_MODAL)
export const setSelectedSeverity = createAction(SET_SELECTED_SEVERITY)

export const setSelectedResource = createAction(SET_SELECTED_RESOURCE)

export const setStatusFilter = createAction(SET_STATUS_FILTER)
