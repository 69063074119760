import _ from 'lodash'
import { Map } from 'immutable'

export const countsBySwimlane = (tickets, swimlanes) => {
  const groupedBySwimlane = _.groupBy(tickets, ticket => {
    return !ticket.swimlaneSrns || !Array.isArray(ticket.swimlaneSrns)
      ? 'undefined'
      : swimlanes
          .find(
            swimlane => swimlane.get('srn') === ticket.swimlaneSrns[0],
            null,
            Map()
          )
          .get('title') || 'undefined'
  })

  const countsBySwimlane = {}
  _.keys(groupedBySwimlane).forEach(swimlaneName => {
    const ticketsArr = groupedBySwimlane[swimlaneName]
    const count = _.get(_.first(ticketsArr), ['riskScore'], 'A')
    countsBySwimlane[swimlaneName] = count
  })

  return countsBySwimlane
}

export const countsByCategoryAndSwimlane = (tickets, swimlanes) => {
  const withExpandedSwimlanes = expandSwimlanes(tickets)
  const groupedBySwimlane = _.groupBy(withExpandedSwimlanes, ticket => {
    return !ticket.swimlaneSrns || !Array.isArray(ticket.swimlaneSrns)
      ? 'undefined'
      : swimlanes
          .find(
            swimlane => swimlane.get('srn') === ticket.swimlaneSrns[0],
            null,
            Map()
          )
          .get('title') || 'undefined'
  })

  const countsBySwimlaneAndCategory = {}
  _.keys(groupedBySwimlane).forEach(swimlaneName => {
    const ticketsArr = groupedBySwimlane[swimlaneName]

    const groupedByCategory = _.groupBy(ticketsArr, ticket => {
      if (ticket.ticketType == 'Framework') {
        return ticket.ticketKey
      }
      return ticket.ticketType
    })

    let grouped = {}

    _.keys(groupedByCategory).forEach(categoryName => {
      const categoryTickets = groupedByCategory[categoryName]

      let sample = { ...categoryTickets[0] }
      sample.count = categoryTickets.reduce(
        (sum, ticket) => (sum += ticket.count),
        0
      )
      grouped[categoryName] = sample
    })

    countsBySwimlaneAndCategory[swimlaneName] = grouped
  })

  return countsBySwimlaneAndCategory
}

const expandSwimlanes = tickets => {
  const ticketsDuplicatedBySwimlane = []

  const ticketsWithSingleSwimlane = tickets.map(ticket => {
    if (
      !ticket.swimlaneSRNs ||
      !Array.isArray(ticket.swimlaneSRNs) ||
      !ticket.swimlaneSRNs.length > 1
    ) {
      return ticket
    }

    const swimlaneSrns = [...ticket.swimlaneSRNs]

    swimlaneSrns.shift()
    swimlaneSrns.forEach(swimlane => {
      const duplicateTicket = { ...ticket }
      duplicateTicket.swimlaneSRNs = [swimlane]
      ticketsDuplicatedBySwimlane.push(duplicateTicket)
    })

    ticket.swimlaneSRNs = [ticket.swimlaneSRNs[0]]
    return ticket
  })

  return ticketsWithSingleSwimlane.concat(ticketsDuplicatedBySwimlane)
}
