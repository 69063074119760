/**
 *
 * ScWelcomeScreen
 *
 */

import React from 'react'
import TextLink from 'components/TextLink'
import Icon from 'components/Icon'
import WithPermission from 'containers/PermissionChecker/WithPermission'
import WithoutPermission from 'containers/PermissionChecker/WithoutPermission'

function ScWelcomeScreen() {
  const styles = {
    base: {
      padding: '1rem 1.5rem',
    },
    header: {
      fontSize: '30px',
      color: '#373835',
      display: 'block',
      textAlign: 'center',
      fontWeight: '300',
      marginBottom: '0.25rem',
    },
    description: {
      fontSize: '17px',
      fontWeight: '300',
      margin: '10px 0px',
    },
    sampleFilter: {
      fontWeight: '300',
      margin: '0.5rem',
    },
    subtitle: {
      fontSize: '17px',
      fontWeight: '400',
    },
    list: {
      listStyleType: 'disc',
      padding: '0rem 2rem',
    },
  }
  return (
    <div style={styles.base}>
      <div style={styles.header}>Security Center</div>
      <div>
        <div style={styles.description}>
          Swimlanes allows users to group cloud resources into functional areas,
          based resource names/patterns, tags or cloud accounts, even across
          different cloud environments. Group critical assets together to
          prioritize issues that affect these resources, from the larger scope
          of information, for faster issue identification, resolution, and
          reduction of risk in these areas.
        </div>
        <div style={styles.description}>
          All data in your cloud accounts will be present in the
          &quot;Global&quot; Swimlane (at the bottom of the table), with other
          swimlanes listed above it. You can filter by a specific swimlane by
          selecting it on the vertical axis, by a specific control framework by
          selecting it on the horizontal axis, or a single swimlane & framework
          list of entries by selecting that entry from the table grid.
        </div>
        <div style={styles.description}>
          <WithPermission permissionName="edit.swimlanes">
            <div>
              To define your swimlanes, select{' '}
              <TextLink
                color="primary"
                to={{
                  pathname: `/App/SwimlaneManagement`,
                }}
              >
                <Icon fa name="column" /> Manage Swimlanes
              </TextLink>{' '}
              here or from the lower left menu.
            </div>
          </WithPermission>
          <WithoutPermission permissionName="edit.swimlanes">
            <div>
              To define swimlanes you must be assigned a role with swimlane.edit
              permissions.
            </div>
          </WithoutPermission>
        </div>
      </div>
    </div>
  )
}

export default ScWelcomeScreen
