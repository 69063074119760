import {
  all,
  put,
  takeEvery,
  select,
  takeLatest,
  call,
} from 'redux-saga/effects'
import _ from 'lodash'
import { getClient } from 'apolloClient'
import gql from 'graphql-tag'

import { mapPoliciesToControlFrameworks } from 'utils/sonraiUtils'
import {
  selectPolicies,
  selectControlGroups,
} from 'containers/ControlFrameworkData/selectors'
import { selectSwimlanes } from 'containers/SonraiData/selectors'

import { TICKET_STATUS } from 'appConstants'
import { updateRapsheetResourceMonitor } from 'containers/RapSheet/actions'
import {
  DISABLE_RESOURCE_MONITORING,
  ENABLE_RESOURCE_MONITORING,
  FETCH_ROLLUP_DATA,
  FETCH_CATEGORY_ROLLUP_DATA,
} from './constants'
import { setCategoryRollupData, setTimelineRollupData } from './actions'

import { countsByCategoryAndSwimlane, countsBySwimlane } from './processData'

function* enableResourceMonitoring(action) {
  const srn = action.payload
  const client = getClient()
  yield client.mutate({
    mutation: gql`
        mutation toggleResourceMonitoring {
          setMonitor(monitorStatusBySrn: {srn: "${srn}", monitor: true }){
            srn
            monitor
          }
        }
      `,
  })

  yield put(updateRapsheetResourceMonitor({ srn, isMonitored: true }))
}

function* disableResourceMonitoring(action) {
  const srn = action.payload

  const client = getClient()

  yield client.mutate({
    mutation: gql`
        mutation toggleResourceMonitoring {
          setMonitor(monitorStatusBySrn: {srn: "${srn}", monitor: false }){
            srn
            monitor
          }
        }
      `,
  })

  yield put(updateRapsheetResourceMonitor({ srn, isMonitored: false }))
}

function* fetchRollupData(action) {
  yield all([
    call(getRollupsForSwimlaneByCategory, action),
    call(getRollupsForSeverityBySwimlane, action),
  ])
}

const getStatus = payload => {
  const defValue = `status: { op: IN_LIST, values: ["${TICKET_STATUS.NEW}", "${TICKET_STATUS.IN_PROGRESS}"] }`

  if (!payload) {
    return defValue
  }
  const { status } = payload
  if (!status || status === 'OPEN') {
    return defValue
  }

  if (status === 'ALL') {
    return `status: { value: "ALL"}`
  }

  return `status: { op: EQ value: "${status}"  }`
}

const getSeverityCategory = payload => {
  const defValue = `severityCategory: { value: "ALL" }`
  if (!payload) {
    return defValue
  }
  const { severityCategory } = payload

  if (!severityCategory) {
    return defValue
  }

  return `severityCategory: { op: EQ value: "${severityCategory}"  }`
}

function* getRollupsForSwimlaneByCategory({ payload }) {
  const status = getStatus(payload)
  const severityCategory = getSeverityCategory(payload)
  try {
    const client = getClient()
    const categoryRollups = yield client.query({
      forceFetch: true,
      fetchPolicy: 'no-cache',
      query: gql`
        query getRollupsForSwimlaneHeatmap {
          totals: TicketRollups(
            where: {
              ticketKey: { value: "ALL" }
              ticketType: { value: "ALL" }

              resourceLabel: { value: "ALL" }
              resourceType: { value: "ALL" }
              ${severityCategory}
              actionClassification: { value: "ALL" }
              resourceSRN: { value: "ALL" }
              orgName: { value: "ALL" }
              ${status}
              account: { value: "ALL" }
            }
          ) {
            items {
              ticketKey
              ticketType
              status
              resourceSRN
              resourceLabel
              resourceType
              severityCategory
              actionClassification
              count
              swimlaneSrns
              riskScore
              riskScoreNumeric
            }
          }
          rollup_01: TicketRollups(where: {
            resourceSRN: { value: "ALL" }
            resourceType: { value: "ALL" }
            resourceLabel: { value: "ALL" }
            actionClassification: { value: "ALL" }
            ${severityCategory}
            ticketKey: { op: NEQ value: "ALL" }
            ticketType: { value: "Framework" }
            orgName: { value: "ALL" }
            account: { value: "ALL" }
            ${status}
          }) {
            count
            items(limit: -1) {
              ... all_the_fields
            }
          }
          rollup_02: TicketRollups(where: {
            resourceSRN: { value: "ALL" }
            resourceType: { value: "ALL" }
            resourceLabel: { value: "ALL" }
            actionClassification: { value: "ALL" }
            ${severityCategory}
            ticketKey: { value: "ALL" }
            ticketType: { op: IN_LIST values: [ "Access", "Activity", "Property" ]}
            orgName: { value: "ALL" }
            account: { value: "ALL" }
            ${status}
          }) {
            items (limit: -1) {
              ... all_the_fields
            }
          }
        }

        fragment all_the_fields on TicketRollup {
          resourceSRN
          ticketKey
          ticketType
          severityCategory
          count
          resourceType
          resourceLabel
          actionClassification
          swimlaneSrns
          riskScore
          riskScoreNumeric
        }
      `,
    })

    if (
      // TODO come back to this, these will be null if no data
      // categoryRollups.data.rollup_01.items === null ||
      // categoryRollups.data.rollup_02.items === null ||
      categoryRollups.data.totals.items === null
    ) {
      yield put(setCategoryRollupData({ rollup: [], totals: [] }))
      throw new Error('Bad formatting of response from server: items is null')
    }

    const controlPolicies = yield select(selectPolicies)
    const controlFrameworks = yield select(selectControlGroups)
    const swimlanes = yield select(selectSwimlanes)

    const policiesToControlFrameworks = mapPoliciesToControlFrameworks(
      [
        ...(categoryRollups.data.rollup_01.items ?? []),
        ...(categoryRollups.data.rollup_02.items ?? []),
      ],
      controlPolicies,
      controlFrameworks
    )
    const dataWithSwimlanes = countsByCategoryAndSwimlane(
      policiesToControlFrameworks,
      swimlanes
    )

    const totals = countsBySwimlane(
      categoryRollups.data.totals.items,
      swimlanes
    )

    yield put(
      setCategoryRollupData({ rollup: dataWithSwimlanes, totals: totals })
    )
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error loading rollup data for swimlanes by category', e)
  }
}

export function* getRollupsForSeverityBySwimlane({ payload }) {
  try {
    const client = yield getClient()
    const status = getStatus(payload)

    const timelineRollup = yield client.query({
      forceFetch: true,
      fetchPolicy: 'no-cache',
      query: gql`
        query getRollupsForTimeline {
          TicketRollups(
            where: {
              ticketType: { value: "ALL" }
              resourceSRN: { value: "ALL" }
              severityCategory: { op: NEQ, value: "ALL" }
              ticketKey: { value: "ALL" }
              actionClassification: { value: "ALL" }
              resourceType: { value: "ALL" }
              resourceLabel: { value: "ALL" }
              orgName: { value: "ALL" }
              account: { value: "ALL" }
              ${status}
            }
          ) {
            count
            items {
              resourceSRN
              ticketType
              ticketKey
              severityCategory
              count
              resourceType
              resourceLabel
              actionClassification
              swimlaneSrns
              status
              riskScore
              riskScoreNumeric
            }
          }
        }
      `,
    })

    if (timelineRollup.data.TicketRollups.items === null) {
      throw new Error('Bad formatting of response from server: items is null')
    }

    const bySeverityCategory = _.groupBy(
      timelineRollup.data.TicketRollups.items,
      ticket => ticket.severityCategory
    )
    yield put(setTimelineRollupData(bySeverityCategory))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error loading rollup data for severity by time', e)
  }
}

function* securityCenterSaga() {
  yield all([
    takeEvery(DISABLE_RESOURCE_MONITORING, disableResourceMonitoring),
    takeEvery(ENABLE_RESOURCE_MONITORING, enableResourceMonitoring),
    takeLatest(FETCH_ROLLUP_DATA, fetchRollupData),
    takeLatest(FETCH_CATEGORY_ROLLUP_DATA, getRollupsForSwimlaneByCategory),
  ])
}

export default securityCenterSaga
